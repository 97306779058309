<template>
  <v-container class="window" style="margin-left:11%; margin-right:11%; width: 78%;">
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" :opacity="opacity" indeterminate color="white"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-dialog v-model="dialogdetailBupotPPh">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Detail Bukti Potong PPh</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-row no-gutters class="px-5">
            <v-col :cols="4">
              <v-card ref="form" class="my-5">
                <v-card-text>
                  <v-toolbar flat>
                    <v-toolbar-title>Dokumen Bukti Potong</v-toolbar-title>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-text-field v-model="dokumenBupot.bupot_number" label="Nomor Dok. BuktiPotong" filled readonly
                    class="mt-5"></v-text-field>
                  <v-text-field v-model="dokumenBupot.bupot_date" label="Tanggal Bukti Potong" filled readonly>
                  </v-text-field>
                  <vuetify-money v-model="dokumenBupot.dpp_amount" label="Jumlah Penghasilan Bruto"
                    v-bind:placeholder="placeholder" readonly disabled v-bind:outlined="outlined"
                    v-bind:clearable="clearable" v-bind:valueWhenIsEmpty="valueWhenIsEmpty" v-bind:options="options" />
                  <vuetify-money v-model="dokumenBupot.percentage" label="Tarif" v-bind:placeholder="placeholder"
                    readonly disabled v-bind:outlined="outlined" v-bind:clearable="clearable"
                    v-bind:valueWhenIsEmpty="valueWhenIsEmpty" v-bind:options="options" />
                  <vuetify-money v-model="dokumenBupot.pph_amount" label="PPh" v-bind:placeholder="placeholder" readonly
                    disabled v-bind:outlined="outlined" v-bind:clearable="clearable"
                    v-bind:valueWhenIsEmpty="valueWhenIsEmpty" v-bind:options="options" />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col :cols="8">
              <v-card class="my-5 mx-2">
                <v-card-text>
                  <v-toolbar flat>
                    <v-toolbar-title>Dokumen Kwitansi</v-toolbar-title>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-data-table :headers="headersKwitansi" :items="dokumenKwt" class="elevation-1 my-5">
                    <template v-slot:[`item.dpp_amount`]="{ item }">
                      {{ formatCurrency(item.dpp_amount) }}
                    </template>
                    <template v-slot:[`item.ppn_amount`]="{ item }">
                      {{ formatCurrency(item.ppn_amount) }}
                    </template>
                    <template v-slot:[`item.pph_amount`]="{ item }">
                      {{ formatCurrency(item.pph_amount) }}
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-card-title>
        Inquiry Refund
      </v-card-title>
      <v-divider></v-divider>
      <v-row class="mx-1 mt-1">
        <v-col cols="3" sm="3" md="3" lg="3">
          <v-text-field v-model="cariData.nomor_bupot" label="Nomor Refund" outlined dense></v-text-field>
        </v-col>
        <v-col cols="2" sm="2" md="2" lg="2">
          <!-- <v-text-field v-model="cariData.tanggal_awal" single-line label="Dari Tanggal" readonly>
            <template v-slot:append-outer>
              <date-picker v-model="cariData.tanggal_awal" />
            </template>
          </v-text-field> -->
          <v-menu v-model="menuTanggalAwal" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="cariData.tanggal_awal" label="Start Date" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on" outlined dense></v-text-field>
            </template>
            <v-date-picker v-model="cariData.tanggal_awal" @input="menuTanggalAwal = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" sm="2" md="2" lg="2">
          <!-- <v-text-field v-model="cariData.tanggal_akhir" single-line label=" Sampai Tanggal" readonly>
            <template v-slot:append-outer>
              <date-picker v-model="cariData.tanggal_akhir" />
            </template>
          </v-text-field> -->
          <v-menu v-model="menuTanggalAkhir" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="cariData.tanggal_akhir" label="End Date" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on" outlined dense></v-text-field>
            </template>
            <v-date-picker v-model="cariData.tanggal_akhir" @input="menuTanggalAkhir = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="5" sm="5" md="5" lg="5" align="end">
          <v-btn color="success" dark @click="searchData" class="mb-2">
            Cari Data
            <v-icon dark right>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="error" dark @click="resetData" class="mb-2 ml-2">
            Reset Data
            <v-icon dark right>
              mdi-minus-circle
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-btn color="success" dark class="mb-4 ml-4 mt-4" @click="updateSubmit" x-large>
        SUBMIT
      </v-btn>
      <v-btn color="error" dark class="mb-4 ml-4 mt-4" @click="updateCancel" x-large>
        Cancel
      </v-btn>
      <v-divider></v-divider>
      <img id='barcode3' style="display:none;" />
      <img id='igr' src="../../../assets/indogrosir.png" style="display:none;" />
      <v-data-table v-model="selected" :headers="headers" :items="inquiryBupot" item-key="refund_id" class="elevation-1"
        show-select :loading="loading" loading-text="Loading... Please wait">
        <template v-slot:[`item.refund_number`]="{ item }">
          <!-- <router-link :to="{name: 'DetailBupot', params: { id: value }}"> -->
          <!-- <a @click="showPdfValidated(item)" class="font-weight-bold">
            {{ item.refund_number }}
          </a> -->
          <a @click="showPdfValidated(item)" v-if="item.status === 'S'" class="font-weight-bold">
            {{ item.refund_number }}
          </a>
          <div v-else-if="item.status === 'D'" class="font-weight-bold">
            {{ item.refund_number }}
          </div>
          <a @click="showPdfValidated(item)" v-else-if="item.status === 'V'" class="font-weight-bold">
            {{ item.refund_number }}
          </a>
          <a @click="showPdfValidated(item)" v-else-if="item.status === 'T'" class="font-weight-bold">
            {{ item.refund_number }}
          </a>
          <a @click="showPdfValidated(item)" v-else-if="item.status === 'R'" class="font-weight-bold">
            {{ item.refund_number }}
          </a>
          <a @click="showPdfValidated(item)" v-else-if="item.status === 'C'" class="font-weight-bold">
            {{ item.refund_number }}
          </a>
          <div v-else-if="item.status === 'E'" class="font-weight-bold">
            {{ item.refund_number }}
          </div>
          <!-- </router-link> -->
        </template>
        <template v-slot:[`item.status_refund`]="{ item }">
          <v-chip :color="getColor(item.status_refund)" dark>
            {{ item.status_refund }}
          </v-chip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div class="font-weight-bold">
            {{ formatValidasi(item.status) }}
          </div>
        </template>
        <template v-slot:[`item.dpp_amount`]="{ item }">
          {{ formatCurrency(item.dpp_amount) }}
        </template>
        <template v-slot:[`item.percentage`]="{ item }">
          {{ formatCurrency(item.percentage) }}
        </template>
        <template v-slot:[`item.pph_amount`]="{ item }">
          {{ formatCurrency(item.pph_amount) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import DatePicker from "../../../components/DatePicker.vue";
import axios from 'axios'
import { mapGetters } from 'vuex'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import JsBarcode from 'jsbarcode';
export default {
  data() {
    return {
      loading: true,
      data: [],
      value2: "1234567.89",
      label: "Value",
      placeholder: " ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "0",
      options: {
        locale: "pt-BR",
        prefix: "IDR",
        suffix: "",
        length: 11,
        precision: 0
      },
      properties: {
        hint: "my hint"
        // You can add other v-text-field properties, here.
      },
      menuTanggalAwal: false,
      menuTanggalAkhir: false,
      customer: [],
      bank: [],
      dokumenBupot: {},
      dialoginputBupot: false,
      dialogdetailBupotPPh: false,
      cariData: [],
      search: '',
      dialog: false,
      singleSelect: false,
      selected: [],
      selected2: [],
      selected3: [],
      inquiryBupot: [],
      selectedIndex: 0,
      selectedItem: {},
      dokumenKwt: [],
      dokumenKwtarray: [],
      dokumenKwtarray2: [],
      dokumenKwtarray3: [],
      customerData: [],
      idRefund: [],
      overlay: false,
      opacity: 0.5,
      headers: [
        { text: 'Status', value: 'status', width: "80px" },
        { text: 'Nomor Refund/BuPot', value: 'refund_number', width: "130px" },
        { text: 'Tanggal Input', value: 'refund_date' },
        { text: 'Tanggal Jatuh Tempo', value: 'bupot_giro_date' },
        { text: 'DPP', value: 'dpp_amount' },
        { text: 'PPh', value: 'pph_amount' },
        { text: 'Jumlah Kwitansi', value: 'JumlahKwitansi', width: "145px" },
        { text: 'Status PPH', value: 'status_pph', width: "130px" },
        { text: 'Status Refund', value: 'status_refund', align: 'center' },
        { text: 'Action', value: 'actions', width: "80px", sortable: false },
      ],
      headersKwitansi: [
        { text: 'Nomor Kwitansi', value: 'kwt_number' },
        { text: 'Tanggal Kwitansi', value: 'kwt_date' },
        { text: 'Jenis Kwitansi', value: 'kwt_type' },
        { text: 'DPP Kwitansi', value: 'dpp_amount' },
        { text: 'PPN Kwitansi', value: 'ppn_amount' },
        { text: 'PPh Kwitansi', value: 'pph_amount' },
      ],
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Role' : 'Edit Role'
    },
    ...mapGetters({
      user: 'user',
    })
  },
  mounted() {
    // if (localStorage.getItem('reloaded')) {
    //     // The page was just reloaded. Clear the value from local storage
    //     // so that it will reload the next time this page is visited.
    //     localStorage.removeItem('reloaded');
    // } else {
    //     // Set a flag so that we know not to reload the page twice.
    //     localStorage.setItem('reloaded', '1');
    //     location.reload();
    // }
  },
  created() {
    // console.log
    // if (this.user.role_id == 2){
    //   this.$router.push({ name: "TrxPageValidator" }).catch(() => {});
    // }
    // if (this.user.role_id == 1){
    //   this.$router.push({ name: "TrxPageKasir" }).catch(() => {});
    // }        
    let uri = `refundindex/${this.$session.get('customer_id')}`;
    axios.get(uri).then(response => {
      this.inquiryBupot = response.data.data;
      this.loading = false;
    });
  },
  methods: {
    getColor(item) {
      if (item === 'Input Refund') {
        return 'primary';
      } else {
        return 'green';
      }
    },
    editItem(item) {
      if (item.status === 'E') {
        this.$router.push({ name: 'EditBuktiPotong', params: { refund_number: item.refund_number, refund_id: item.refund_id } });
      } else {
        this.$swal.fire(
          'Peringatan!',
          'Hanya Nomor Refund yang berstatus Edit yang  dapat direvisi!',
          'warning'
        )
      }
      // let uri = `dtltempbupot/${item.bupot_id}`;
      //     axios.get(uri).then(response => {
      //         this.dokumenBupotModal = response.data.data;
      //     });        
      // let uri2 = `dtltempkwt/${item.bupot_id}/${this.$session.get('customer_id')}`;
      //     axios.get(uri2).then(response => {
      //         this.dokumenKwtModal = response.data.data;
      //         console.log(this.dokumenKwtModal);
      //     });         
      //   this.dialogdetailBupotPPh = true
    },
    searchData() {
      if (typeof (this.cariData.tanggal_awal) !== 'undefined' && typeof (this.cariData.tanggal_akhir) !== 'undefined') {
        var d1 = new Date(this.cariData.tanggal_awal)
        var d2 = new Date(this.cariData.tanggal_akhir)
        if (d1 < d2) {
          this.loading = true;
          axios({
            method: 'post',
            url: 'searchdata',
            data: {
              nomor_bupot: this.cariData.nomor_bupot,
              tanggal_awal: this.cariData.tanggal_awal,
              tanggal_akhir: this.cariData.tanggal_akhir,
              cust_id: this.$session.get('customer_id')
            },
          })
            .then(response => {
              this.inquiryBupot = response.data.data;
              this.loading = false;
              // console.log(this.inquiryBupot)
            })
            .catch(error => {
              console.log(error.response)

            })
        } else {
          this.$swal.fire(
            'Peringatan!',
            'Tanggal Akhir Tidak Boleh Lebih Kecil Dari Tanggal Awal',
            'warning'
          )
        }
      } else {
        this.$swal.fire(
          'Peringatan!',
          'Harap Isi Semua Tanggal!',
          'warning'
        )
      }
    },
    resetData() {
      this.loading = true;
      this.cariData = [];
      let uri = `refundindex/${this.$session.get('customer_id')}`;
      axios.get(uri).then(response => {
        this.inquiryBupot = response.data.data;
        // console.log(this.inquiryBupot);
        this.loading = false;
      });
    },
    formatCurrency2(value) {
      var formatter = new Intl.NumberFormat('en-US', {
      });

      return formatter.format(value); /* $2,500.00 */
    },
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'IDR',
      });

      return formatter.format(value); /* $2,500.00 */
    },
    formatValidasi(value) {
      if (value == "D") {
        return "Draft"
      } else if (value == "S") {
        return "Submit"
      } else if (value == "V") {
        return "Valid"
      } else if (value == "R") {
        return "Reject"
      } else if (value == "C") {
        return "Cancel"
      } else if (value == "E") {
        return "Edit"
      } else if (value == "T") {
        return "Completed"
      }
    },
    showDialog(item) {
      let uri = `dtltrxpage/${item.bupot_id}`;
      axios.get(uri).then(response => {
        this.dokumenBupot = response.data.data;
      });
      let uri2 = `dtlKwtpage/${item.bupot_id}/${this.$session.get('customer_id')}`;
      axios.get(uri2).then(response => {
        this.dokumenKwt = response.data.data;
        // console.log(this.dokumenKWt);
      });
      this.dialogdetailBupotPPh = true
    },
    closeDialog() {
      this.dialogdetailBupotPPh = false
      this.dokumenBupot = {}
      this.dokumenKwt = []
    },
    updateSubmit() {
      var count = 0;
      if (this.selected.length == 0) {
        this.$swal.fire(
          'Peringatan!',
          'Silahkan Pilih Bukti Potong yang mau disubmit!',
          'warning'
        )
      } else {
        this.idRefund.splice(0);
        this.selected.forEach(item => {
          this.idRefund.push(item.refund_id)
          if (item.status != "D") {
            count = count + 1;
          }
        })
        if (count > 0) {
          this.$swal.fire(
            'Peringatan!',
            'Hanya Bukti Potong yang berstatus draft saja yang bisa di submit!',
            'warning'
          )
        } else {
          this.$swal.fire({
            title: 'Apakah anda yakin ingin submit data ini?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
          }).then((result) => {
            if (result.isConfirmed) {
              axios({
                method: 'post',
                url: 'updatestatusrefundsubmit',
                data: {
                  id_refund: this.idRefund
                },
              })
                .then(response => {
                  this.$swal.fire(
                    'Sukses!',
                    'Data berhasil di submit!',
                    'success'
                  )
                  let uri = `refundindex/${this.$session.get('customer_id')}`;
                  axios.get(uri).then(response => {
                    this.inquiryBupot = response.data.data;
                  });
                  this.selected = [];
                  console.log(response)
                })
                .catch(error => {
                  console.log(error.response)
                })
            }
          })
        }
      }
    },
    updateCancel() {
      var count = 0;
      if (this.selected.length == 0) {
        this.$swal.fire(
          'Peringatan!',
          'Silahkan Pilih Bukti Potong yang mau dicancel!',
          'warning'
        )
      } else {
        this.idRefund.splice(0);
        this.selected.forEach(item => {
          this.idRefund.push(item.refund_id)
          if (item.status != "D") {
            count = count + 1;
          }
        })
        if (count > 0) {
          this.$swal.fire(
            'Peringatan!',
            'Hanya Bukti Potong yang berstatus draft saja yang bisa di cancel!',
            'warning'
          )
        } else {
          this.$swal.fire({
            title: 'Apakah anda yakin ingin cancel data ini?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
          }).then((result) => {
            if (result.isConfirmed) {
              axios({
                method: 'post',
                url: 'updatestatusrefundcancel',
                data: {
                  id_refund: this.idRefund
                },
              })
                .then(response => {
                  this.$swal.fire(
                    'Sukses!',
                    'Data berhasil di cancel!',
                    'success'
                  )
                  let uri = `refundindex/${this.$session.get('customer_id')}`;
                  axios.get(uri).then(response => {
                    this.inquiryBupot = response.data.data;
                  });
                  this.selected = [];
                  console.log(response)
                })
                .catch(error => {
                  console.log(error.response)
                })
            }
          })
        }
      }
    },
    headRows() {
      return [
        { id: 'ID', name: 'Name', email: 'Email', city: 'City', expenses: 'Sum' },
      ]
    },
    bodyRows(rowCount) {
      rowCount = rowCount || 10
      var body = []
      for (var j = 1; j <= rowCount; j++) {
        body.push({
          id: j,
          name: this.$faker().name.findName(),
          email: this.$faker().internet.email(),
          city: this.$faker().address.city(),
          expenses: this.$faker().finance.amount(),
        })
      }
      return body
    },
    showPdfValidated(item) {
      /* eslint-disable */
      this.overlay = true;
      var today = new Date();
      var watermark = '';
      if (item.status === 'V') {
        watermark = 'VALIDATED'
      } else if (item.status === 'T') {
        watermark = 'COMPLETED'
      } else if (item.status === 'R') {
        watermark = 'REJECTED'
      } else if (item.status === 'C') {
        watermark = 'CANCEL'
      }
      var today = new Date();
      const formattedDate = today.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
      }).replace(/ /g, '-');
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      var hour = today.getHours();

      today = formattedDate;
      var currentHour = hour + ':' + minutes + ':' + seconds;
      JsBarcode("#barcode3", item.refund_number);
      this.dokumenKwtarray3.splice(0);
      var sum = 0;
      var sumb = 0;
      var sumc = 0;
      var i = 1;
      var sumTotalDppRefund = 0;
      var sumTotalPPhRefund = 0;
      let uri = `getcustnumberandname/${this.$session.get('customer_id')}`;
      axios.get(uri).then(response => {
        this.customer = response.data.data;
        let uri2 = `getkwtarray/${item.refund_id}/${this.$session.get('customer_id')}`;
        axios.get(uri2).then(response => {
          this.dokumenKwtarray = response.data.data;
          try {
            this.dokumenKwtarray2 = this.dokumenKwtarray.map(this.getKwtValue)
          }
          catch (err) {
            console.log(err);
          }
          this.data = this.dokumenKwtarray
          this.data.forEach(item => {
            this.dokumenKwtarray3.push({
              bupot_number: item.bupot_number,
              bupot_date: item.bupot_date,
              dpp_amount_bupot: this.formatCurrency2(item.dpp_amount_bupot),
              pph_amount_bupot: this.formatCurrency2(item.pph_amount_bupot),
              kwt_id: item.kwt_id,
              kwt_number: item.kwt_number,
              kwt_date: item.kwt_date,
              dpp_amount: this.formatCurrency2(item.dpp_amount),
              ppn_amount: this.formatCurrency2(item.ppn_amount),
              pph_amount: this.formatCurrency2(item.pph_amount2),
              tarif: item.tarif
            })
          })
          // console.log(this.dokumenKwtarray);
          this.dokumenKwtarray.forEach(item => {
            sum = sum + parseInt(item.dpp_amount);
          })
          this.dokumenKwtarray.forEach(item => {
            sumb = sumb + parseInt(item.ppn_amount);
          })
          this.dokumenKwtarray.forEach(item => {
            sumc = sumc + parseInt(item.pph_amount2);
          })
          sum = this.formatCurrency2(sum);
          sumb = this.formatCurrency2(sumb);
          sumc = this.formatCurrency2(sumc);
          // console.log(sumc);
          sumTotalDppRefund = this.formatCurrency2(item.dpp_amount);
          sumTotalPPhRefund = this.formatCurrency2(item.pph_amount);
          try {
            this.dokumenKwtarray3 = this.dokumenKwtarray3.map(this.getKwtValue)
          }
          catch (err) {
            console.log(err);
          }
          var raw = this.dokumenKwtarray3
          var body = []
          var bupotIdonly = []
          var newIdbupotonly = []
          var index = 1;
          var count = 0;
          var counts = [];
          var indikator = 1;
          var activeBupot = "";
          raw.forEach(item => [
            bupotIdonly.push({
              bupotNumber: item[0]
            })
          ])
          newIdbupotonly = bupotIdonly.map(this.getBupotNumber);
          newIdbupotonly.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
          var j = 0;
          for (i = 0; i < raw.length; i++) {
            var row = []
            var nextRow = []
            for (var key in raw[i]) {
              row.push(raw[i][key])
            }
            var no_bupot = row[0];
            var tanggal_bupot = row[1];
            var total_dpp_bupot = row[6];
            var total_pph_bupot = row[8];
            var no_kwitansi = row[4];
            var tgl_kwitansi = row[5];
            var tarif = row[9];
            row.shift();
            row.shift();
            row.shift();
            row.shift();
            row.shift();
            row.shift();
            row.unshift({
              content: tgl_kwitansi,
              styles: { valign: 'middle', halign: 'center' },
            })
            row.unshift({
              content: no_kwitansi,
              styles: { valign: 'middle', halign: 'center' },
            })
            row.unshift({
              content: index,
              styles: { valign: 'middle', halign: 'center' },
            })
            row.push({
              content: no_bupot,
              styles: { valign: 'middle', halign: 'center' },
            })
            row.push({
              content: tarif,
              styles: { valign: 'middle', halign: 'center' },
            })
            row.push({
              content: total_dpp_bupot,
              styles: { valign: 'middle', halign: 'right' },
            })
            row.push({
              content: total_pph_bupot,
              styles: { valign: 'middle', halign: 'right' },
            })
            row.push({
              content: tanggal_bupot,
              styles: { valign: 'middle', halign: 'center' },
            })
            row.splice(3, 4);
            index = index + 1;
            body.push(row);
          }
          // console.log(body);
          const img = document.querySelector('img#barcode3');
          const logo = require('@/assets/indogrosir.png')
          var imgLogo = new Image()
          imgLogo.src = logo
          // var img3 = new Image();
          // img3.url = '../../../assets/indogrosir.png';
          var cust_name = this.customer.map(({ customer_name }) => customer_name)
          var cust_number = this.customer.map(({ customer_number }) => customer_number)
          var nama_bank = this.bank.map(({ nama_bank }) => nama_bank)
          // console.log(nama_bank);
          // console.log(splitString.length);
          var header = cust_name;
          var tglKembali = this.formatDate2(item.bupot_giro_date);
          if (item.bupot_giro_date == null) {
            tglKembali = null;
          }
          var validationDate = this.formatDate2(item.validation_date);
          if (item.validation_date == null) {
            validationDate = null;
          }
          // doc.text(header, 13, 5, { baseline: 'middle' });
          var doc = new jsPDF();
          // doc.addImage("../../../assets/indogrosir.png", 'JPEG', 55, 25,100,25);
          doc.setFont("helvetica", "bold");
          doc.getFontList();
          if (item.status === 'T' || item.status === 'V') {
            // doc.text("TANDA TERIMA BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
            if (item.status_refund === 'Input Refund') {
              doc.text("TANDA TERIMA REFUND BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
            } else {
              doc.text("TANDA TERIMA PENYERAHAN BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
            }
          } else {
            if (item.status_refund === 'Input Refund') {
              doc.text("TANDA TERIMA REFUND BUKTI POTONG SEMENTARA", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
            } else {
              doc.text("TANDA TERIMA PENYERAHAN BUKTI POTONG SEMENTARA", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
            }
          }
          doc.setFont("helvetica", "normal");
          doc.setFontSize(10);
          doc.addImage(img.src, 'JPEG', 80, 40, 50, 25);
          doc.addImage(imgLogo, 'PNG', 5, 5, 50, 20)
          doc.text("Nomor TT : " + item.refund_number + ' / ' + item.status_pph, 80, 38, { baseline: 'middle', align: 'left', lineHeightFactor: '0.5' });
          doc.setFontSize(7);
          doc.text("Supplier", 155, 7, { align: 'left', lineHeightFactor: '0.5' });
          doc.setFontSize(7);
          doc.text(header, 155, 12, { align: 'left', lineHeightFactor: '0.5' });
          doc.text(this.user.supp_site_name, 155, 17, { align: 'left', lineHeightFactor: '0.5' });
          // doc.setFontSize(7);
          // if (splitString.length > 1) {
          //   doc.text('Nama Bank                    : ' + splitString[0], 145, 22);
          //   doc.text('                                         ' + concatKedua, 145, 27);
          //   doc.setFontSize(7);
          //   doc.text('Nama Pemilik Rekening : ' + nama_pemilik_rekening, 145, 32);
          //   doc.setFontSize(7);
          //   doc.text('Kode Sandi Bank           : ' + kode_sandi_bank, 145, 37);
          //   doc.setFontSize(7);
          //   doc.text('Nomor Rekening            : ' + nomor_rekening, 145, 42);
          // } else {
          //   doc.text('Nama Bank                    : ' + splitString[0], 145, 22);
          //   doc.setFontSize(7);
          //   doc.text('Nama Pemilik Rekening : ' + nama_pemilik_rekening, 145, 27);
          //   doc.setFontSize(7);
          //   doc.text('Kode Sandi Bank           : ' + kode_sandi_bank, 145, 32);
          //   doc.setFontSize(7);
          //   doc.text('Nomor Rekening            : ' + nomor_rekening, 145, 37);
          // }
          doc.setFontSize(9);
          doc.autoTable({
            columnStyles: {
              0: { cellWidth: 10 },
              1: { cellWidth: 34 },
              2: { cellWidth: 25 },
              3: { cellWidth: 30 },
              4: { cellWidth: 16 },
              5: { cellWidth: 30 },
              6: { cellWidth: 30 },
              7: { cellWidth: 25 }
            },
            headStyles: {
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              fillColor: [255, 255, 255],
              textColor: 'black'
            },
            theme: 'grid',
            head: [[
              { content: 'No ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Nomor Invoice / Kwitansi ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Tgl Kwitansi ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Nomor Bupot ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Tarif PPH(%) ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Nilai (Rp.) ', colSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'Tgl BuPot ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
            ], [
              { content: 'DPP', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: 'PPH', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
            ]],
            bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
            startY: 70,
            margin: { top: 5, left: 5, right: 2 },
            body: body,
            footStyles: {
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              fillColor: [255, 255, 255],
              textColor: 'black'
            },
            rowPageBreak: 'auto'
          })
          let finalY = doc.lastAutoTable.finalY;
          doc.setFont("helvetica", "bold");
          doc.autoTable({
            columnStyles: {
              0: { cellWidth: 115 },
              1: { cellWidth: 30 },
              2: { cellWidth: 30 },
              3: { cellWidth: 25 },
            },
            bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
            body: [[
              { content: 'TOTAL', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              { content: sum, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
              { content: sumc, colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
              { content: '', colSpan: 1, styles: { halign: 'right', fillColor: [224, 224, 235], textColor: 'black', fontSize: 9 } }
            ]],
            margin: { top: 5, left: 5, right: 2 },
            startY: finalY,
            lineColor: [0, 0, 0]
          })
          finalY = doc.lastAutoTable.finalY;
          finalY = finalY + 10; // The y position on the page
          if (item.status === 'T' || item.status === 'V') {
            doc.setFont("helvetica", "bold");
            doc.text(5, finalY, "Catatan");
            finalY = finalY + 5;
            doc.setFont("helvetica", "normal");
            doc.text(5, finalY, "\u2022 " + "Di-validasi pada tanggal " + validationDate);
            finalY = finalY + 5;
            if (item.status === 'V') {
              doc.text(5, finalY, "\u2022 " + "Tanggal Pembayaran Refund PPh :" + tglKembali);
              finalY = finalY + 5;
            }
          } else {
            doc.setFont("helvetica", "bold");
            doc.text(5, finalY, "Catatan");
            finalY = finalY + 5;
            doc.setFont("helvetica", "normal");
            doc.text(5, finalY, "\u2022 " + "Dicetak pada tanggal " + today + " pukul " + currentHour);
            finalY = finalY + 5;
            doc.text(5, finalY, "\u2022 " + "Mohon melampirkan BuPot, fotocopy Kwitansi dan fotocopy Faktur Pajak");
            finalY = finalY + 5;
          }
          // doc.autoTable({ 
          //     columnStyles: {
          //       0: {cellWidth:8},
          //       1: {cellWidth:30, fontStyle: 'bold', fontSize: 9}, 
          //       2: {halign:'center', cellWidth:20, fontSize: 8},
          //       3: {cellWidth:20, fontSize: 9,halign:'right'},
          //       4: {cellWidth:20 , fontSize: 9,halign:'right'},
          //       5: {halign:'center', cellWidth:30, fontSize: 9,fontStyle: 'bold'},
          //       6: {halign:'center', fontSize: 8,cellWidth:20},
          //       7: {halign:'right', cellWidth:20, fontSize: 9},
          //       8: {halign:'right', cellWidth:20, fontSize: 9},
          //       9: {halign:'right', cellWidth:20, fontSize: 9},
          //       10:{halign:'right', cellWidth:20, fontSize: 9},
          //     }, 
          //     bodyStyles : {lineColor: [0, 0 ,0 ]},
          //     headStyles: {
          //         lineWidth: 0.5,
          //         lineColor: [0, 0, 0],
          //         fillColor: [255, 255, 255],
          //         textColor:'black'
          //     },
          //     footStyles: {
          //         lineWidth: 0.5,
          //         lineColor: [0, 0, 0],
          //         fillColor: [255, 255, 255],
          //         textColor:'black'
          //     },                 
          //     theme: 'grid',
          //     head: [[
          //       {content: 'No ',rowSpan:2,styles: {halign: 'center',valign:'center', fillColor: [255, 255, 255], textColor:'black'}},
          //       {content: 'Bupot ', colSpan: 4,styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
          //       {content: 'Kwitansi', colSpan: 5, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}}
          //     ],[
          //       {content: 'Nomor', colSpan: 1,styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
          //       {content: 'Tanggal', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
          //       {content: 'DPP', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
          //       {content: 'PPH', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
          //       {content: 'Nomor', colSpan: 1,styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
          //       {content: 'Tanggal', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
          //       {content: 'DPP', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
          //       {content: 'PPN', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
          //       {content: 'PPH', colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}}
          //     ],
          //     ],
          //     body: body,
          //     margin: {top: 90, left: 1, right: 1},
          //     foot: [[
          //       {content: 'Grand total Bupot', colSpan: 3, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
          //       {content: sumTotalDppRefund, colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
          //       {content: sumTotalPPhRefund, colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
          //       {content: 'Grand total Kwt', colSpan: 2, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
          //       {content: sum, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
          //       {content: sumb, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}},
          //       {content: sumc, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 9}}
          //     ]]
          //   })
          if (item.status !== 'T' && item.status !== 'V') {
            //   doc.autoTable({
            //   bodyStyles : {lineColor: [0, 0 ,0 ]},
            //   headStyles: {
            //       lineWidth: 0.5,
            //       lineColor: [0, 0, 0]
            //   },             
            //   theme : 'grid',
            //   head: [[{content: 'Validation Notes', colSpan: 2, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}}]],
            //   body: [
            //     ['Tgl. Validasi Refund:  ', validationDate],
            //     ['Tgl. Cetak  Refund:  ', today],
            //     ['Tgl. Kembali:  ', tglKembali],
            //     ['Paraf Petugas Validasi:  ', '                          '],
            //     ['Nama Petugas:  ', '                          '],
            //   ],
            //   margin: {left: 135},
            //   startY: 215,
            //   lineColor: [0,0,0]
            // })
            if (item.status === 'T' || item.status === 'V') {
              // doc.text("TANDA TERIMA BUKTI POTONG", 105, 30, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
              doc.autoTable({
                bodyStyles: { lineColor: [0, 0, 0] },
                headerStyles: {
                  lineWidth: 0,
                  lineColor: [0, 0, 0]
                },
                theme: 'grid',
                head: [[{ content: 'Catatan', colSpan: 2, styles: { halign: 'Left', fillColor: [255, 255, 255], textColor: 'black' } }]],
                body: [
                  ['Tgl. Validasi Refund:  ', validationDate],
                  ['Tgl. Pembayaran Refund:  ', '                          '],
                  ['Paraf Petugas Validasi:  ', '                          '],
                  ['Nama Petugas:  ', '                          '],
                ],
                margin: { left: 135 },
                startY: finalY,
                lineColor: [0, 0, 0]
              })
            } else {
              if (item.status_refund === 'Input Refund') {
                doc.autoTable({
                  bodyStyles: { lineColor: [0, 0, 0] },
                  headerStyles: {
                    lineWidth: 0,
                    lineColor: [0, 0, 0]
                  },
                  theme: 'grid',
                  head: [[{ content: 'Catatan', colSpan: 2, styles: { halign: 'Left', fillColor: [255, 255, 255], textColor: 'black' } }]],
                  body: [
                    ['Tgl. Validasi Refund:  ', validationDate],
                    ['Tgl. Pembayaran Refund:  ', '                          '],
                    ['Paraf Petugas Validasi:  ', '                          '],
                    ['Nama Petugas:  ', '                          '],
                  ],
                  margin: { left: 135 },
                  startY: finalY,
                  lineColor: [0, 0, 0]
                })
              } else {
                doc.autoTable({
                  bodyStyles: { lineColor: [0, 0, 0] },
                  headerStyles: {
                    lineWidth: 0,
                    lineColor: [0, 0, 0]
                  },
                  theme: 'grid',
                  head: [[{ content: 'Catatan', colSpan: 2, styles: { halign: 'Left', fillColor: [255, 255, 255], textColor: 'black' } }]],
                  body: [
                    ['Tgl. Validasi Refund:  ', validationDate],
                    ['Paraf Petugas Validasi:  ', '                          '],
                    ['Nama Petugas:  ', '                          '],
                  ],
                  margin: { left: 135 },
                  startY: finalY,
                  lineColor: [0, 0, 0]
                })
              }
            }
          }
          if (item.status === 'V') {
            var totalPages = doc.internal.getNumberOfPages();
            for (i = 1; i <= totalPages; i++) {
              doc.setPage(i);
              doc.saveGraphicsState();
              doc.setGState(new doc.GState({ opacity: 0.2 }));
              doc.setFontSize(100)
              doc.setTextColor(255, 0, 0);
              doc.text(watermark, 55, 200, null, 45)
              doc.restoreGraphicsState();
            }
          } else if (item.status === 'T') {
            var totalPages = doc.internal.getNumberOfPages();
            for (i = 1; i <= totalPages; i++) {
              doc.setPage(i);
              doc.saveGraphicsState();
              doc.setGState(new doc.GState({ opacity: 0.2 }));
              doc.setFontSize(100)
              doc.setTextColor(255, 0, 0);
              doc.text(watermark, 40, 250, null, 45)
              doc.restoreGraphicsState();
            }
          } else if (item.status === 'R') {
            var totalPages = doc.internal.getNumberOfPages();
            for (i = 1; i <= totalPages; i++) {
              doc.setPage(i);
              doc.saveGraphicsState();
              doc.setGState(new doc.GState({ opacity: 0.2 }));
              doc.setFontSize(100)
              doc.setTextColor(255, 0, 0);
              doc.text(watermark, 55, 200, null, 45)
              doc.restoreGraphicsState();
            }
          } else if (item.status === 'C') {
            var totalPages = doc.internal.getNumberOfPages();
            for (i = 1; i <= totalPages; i++) {
              doc.setPage(i);
              doc.saveGraphicsState();
              doc.setGState(new doc.GState({ opacity: 0.2 }));
              doc.setFontSize(100)
              doc.setTextColor(255, 0, 0);
              doc.text(watermark, 55, 200, null, 45)
              doc.restoreGraphicsState();
            }
          }
          // var totalPages = doc.internal.getNumberOfPages();
          // for (i = 1; i <= totalPages; i++) {
          //   doc.setPage(i);
          //   doc.saveGraphicsState();
          //   doc.setGState(new doc.GState({opacity: 0.2}));
          //   doc.setFontSize(100)
          //   doc.setTextColor(255, 0, 0);
          //   doc.text(watermark, 55, 200, null, 45)
          //   doc.restoreGraphicsState();  
          // }
          // doc.saveGraphicsState();
          // doc.setGState(new doc.GState({opacity: 0.2}));
          // doc.setFontSize(100)
          // doc.setTextColor(255, 0, 0);
          // doc.text(watermark, 55, 200, null, 45)
          // doc.restoreGraphicsState();              
          doc.setProperties({
            title: "Laporan Bukti Potong"
          });
          this.overlay = false;
          window.open(doc.output('bloburl'))
        });
        this.dokumenKwtarray2.splice(0);

      });
    },
    getKwtValue(item) {
      var data = [item.bupot_number, item.bupot_date, item.dpp_amount_bupot, item.pph_amount_bupot, item.kwt_number, item.kwt_date, item.dpp_amount, item.ppn_amount, item.pph_amount, item.tarif];
      return data;
    },
    getBupotNumber(item) {
      var data = [item.bupotNumber];
      return data;
    },
    formatDate(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    formatDate2(value) {
      return moment(value).format("DD-MMM-YYYY");
    },
    opendialoginputbupot() {
      this.dialoginputBupot = true;
    }
  },
  components: {
    DatePicker
  }
}
</script>